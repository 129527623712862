.appHeader {
    background-color: blanchedalmond;    
    height: 100px;
    width: 100%;
}

.header-user-info {
    height: auto;
    width: auto;
    background-color: white;
    z-index: 100;
    padding: 20px 10px;
    position: relative;
    border-radius: 5px;    
    text-align: center;
  }

  #header-container {
    width: 100%;;
   }
   
   #user-type{
     color: #1044F4;
     font-weight: 550;
   }
   
   #user-role{
     color: black;
   }
   
   #header-logout{
     color: white;  
     background-color: #FF7D05;
     font-weight: 500;
     border-color: lightgray;
     border-width: 1px;
     border-radius: 3px;
     width: 100px;
     margin-top: 15px;
     font-size: 16px;
     cursor: pointer;
   }
