/* .ant-row {
  width: calc(50%);
  justify-content: space-between;
  margin: 0;
} */

.ant-form-item {
  width: calc(50%);
  justify-content: space-between;
  margin: 0;
}

.form-item-row {
  width: calc(50%);
  justify-content: space-between;
  margin: 0;
}

.custom-input {
  padding: 0.5rem;
  margin: 0;
}

.ant-form-item-label {
  text-align: left !important;
}