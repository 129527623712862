.actionwindow {
    background-color: white;
    overflow: auto;
    left: 250px;
    width: 100%;
    height: 100%;
    background-image: url(Assets/WheatBackground.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.mainActionWindow {
    background: lightgray;
    height: calc(100% - 100px);
    width: calc(100% - 200px);
    position: relative;
    left: 200px;
}

.loginWindow {
height: 100%
}

@media only screen and (max-width: 768px) {   
.mainActionWindow {
    background: lightgray;
    height: calc(100% - 100px);
    width: 100%;
    position: relative;
    left: 0px;
}
}
