.account-setting-page {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.account-setting-password-display {
  display: flex;
  flex-direction: row;
}

.account-settings-label {
  font-weight: bold;
  margin-right: 10px;
}

.account-setting-section {
  margin-bottom: 10px;
}

.account-setting-form {
  display: flex;
  flex-direction: column;
}

.account-setting-input {
  width: 40%;
  display: flex;
  justify-content: space-between;
  margin: 5px;
  align-items: center;
}

.change-password-error {
  width: 40%;
  color: red;
  text-align: center;
  vertical-align: middle;
  padding-left: 420px;
  /* padding-top: 15px; */
  /* margin-bottom: 20px; */
    /* border: 1px solid red;
  border-radius: 15px;
  background-color: #FF9494; */
}


.change-password-submit {
  border-radius: 10px;
  border: 1px solid #00732E;
  background-color: #00732E;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 205px;
  margin-top: 20px;
  /* margin-left: 440px; */
  margin-left: 275px;
  transition: transform 80ms ease-in;
}

@media only screen and (max-width: 768px) {
  .change-password-submit{
    margin-left: 90px;
  }  
}