.vendor-detail-label {
    font-weight: 550;

}
#editIcon {
    float: right;
    padding: 5px;
}

#VendorInfoBox
{
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: .25px;
    border-color: grey;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    height: 60px;
}

#filterBox
{
    height: 100px;
    background-color: darkgreen;
    font-size: xx-large;
}


.form > text
{
    text-align: right;
   font-size: xx-large;
   padding: 10px;
    float: left;
    height: 30px;
   width:  30%;
}

.optionsSelect
{
   position: relative;
   top: -10px;
   float: right;
}

.container {
    display: flex;
    flex-direction: column;
    margin: 1.5rem;
  }

.button-holder {
    margin-top: 10px;
    width: 100%;
    float: right;
}

.middle-button {
    display: block;
    margin: 3px auto;
    padding: 5px;
    height: 50px;
    background-color: #F39801;
    border: rgb(255, 255, 255);
    border-radius: 5px;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    min-width: 190px;
}

button:hover { 
    background-color: #9e6201;
}

.return-button
{
    width: 100%;
}
.searchGrid
{
    margin-top: 100px;
    height: 100%;
}

.standardform > div
{
    background: rgb(255, 255, 255);
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.standardform > input
{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.inputlist
{
    margin-top: 10px;
}

.inputlist > span
{
    margin-left: 10px;
    float: left;
    font-weight: 700;
    font-size: x-large;
}

#vendorfilter
{
    margin-top: 10px;
    margin-left: 20px;
    margin-right: auto;
    width: 80%;
    height: 50px;
    font-size: larger;
    font-weight: 600;
}

.formbutton
{
    position: relative;
    font-weight: 700;
    font-size: x-large;
    background-color: rgb(97, 200, 102);
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    height: 50px;
    text-align: center;
    border-radius: 15px;
}

.vendor-form
{
    padding: 1px;    
}

#return-button
{
    float:right;
}

.modal 
{
    position: fixed;
    z-index: 13;
    background-color: rgba(0, 0, 0, 0.25);
}
  
.modal_content {
    position: absolute;
    width: 500px;
    height: 550px;
    left: calc(50vw - 500px);
    top: calc(50vh - 500px);
    
    background: #E1EFE7;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    overflow-y: hidden;
}

.popup-modal {
    position: fixed;
    z-index: 13;
    background-color: rgba(0, 0, 0, 0.25);
}

.popup-modal_content {
    position: absolute;
    width: 500px;
    height: auto;
    left: calc(50vw - 500px);
    top: calc(50vh - 500px);
    background: #E1EFE7;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    overflow-y: hidden;
    padding: 20px 20px 30px 20px;    
}

.user-window
{
    width: 100%;
}

.pop-up-header
{
    display: block;
    margin: 30px 0px 0px 0px;
    text-align: center;
    font-size: large;
    font-weight: 600;
}

.left-button {
    margin: 10px;
    float: right;
    background: #00732E;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    height: 50px;            
    font-family: Arial;
    font-style: normal;
    font-weight: bold;    
    line-height: 16px;
    min-width: 190px;
}
.left-button-cancel {
    margin: 10px;
    float: right;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 50px;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    min-width: 190px;
}

.standard-form-item
{
    border: black;
}

.radio-form-input
{
    margin: 20px;
    border: .5px;
    border-style: solid;
    width: 95%;
    height: 56px;
    border-radius: 5px;
    position: relative;
    color: #8f8f8f;
    background-color: rgba(141, 141, 141, 0.3);
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
}

.radio-list
{
   height: 100%;
    float: right;
    min-width: 90px;
}

.vendor-loading-icon {
    text-align: center;
    margin-top: 200px;
}

.active-vendor-row {
    background-color: peachpuff;
    } 
    
.inactive-vendor-row{
        background-color: lightgrey;
    }

.vendor-header-cell
    {
       background-color: lightblue !important;       
    } 

.ant-table-thead tr th.ant-table-cell {
        font-weight: bold;
    }

    .container {
        display: flex;
        flex-direction: column;
        margin: 1.5rem;
      }
      
      .return-button {
        display: flex;
        justify-content: space-between;
      }
      
      .form-header {
        margin-bottom: 0;
      }
    