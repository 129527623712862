* {
  box-sizing: border-box;
}

body {
  background: #673ab7;
  background: linear-gradient(to right, #512da8, #673ab7);
}

.field 
{
  margin: 20px;

  border: .5px;
  border-style: solid;  
  height: 56px;
  border-radius: 5px;
  position: relative;
  color: #8f8f8f;
  background-color: rgba(141, 141, 141, 0.3);
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
  }

.field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.field.active {
  background-color: #ffffff;
  box-shadow: 0px px 20px 0px rgba(0, 0, 0, 0.2);
}

.field.active input {
  padding: 24px 16px 8px 16px;
}

.field.active input + label {
  top: 4px;
  opacity: 1;
  color: #512da8;
}

.field.locked {
  pointer-events: none;
}

.field input {
  width: 100%;
  height: 56px;
  position: relative;
  padding: 0px 16px;
  border: none;
  border-radius: 4px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: #282828;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
}

.field select {
  width: 100%;
  height: 56px;
  position: relative;
  padding: 0px 10px;
  border-radius: 4px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: #282828;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
}

.field input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.field input + label {
  position: absolute;
  top: 24px;
  left: 16px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  color: #7c7c7c;
  opacity: 40;
  pointer-events: none;
  transition: 0.1s all ease-in-out;
}

.field input + label.error {
  color: #ec392f;
}

.field p.predicted {
  position: absolute;
  top: 8px;
  left: 16px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #e0e0e0;
  opacity: 1;
  pointer-events: none;
}

#input-field {
    width: 75% !important;
    margin-left: 20px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    float: left;
}

.input-radio-form {
width: 100%;
overflow: hidden;
}

.radio-input {
padding-top: 5px;
}

.radio-label {
    margin-left: 40px;
    font-weight: normal;
}

.label-radio-button {
    margin-right: 50px;
    float: right;    
    font-weight: 100 !important;
}

#radioId {
padding-left: 5px;}

.date-picker {

margin-bottom: 95px;}

.date-box-label {
    float: left;
    margin-left: 20px;
}

.dateDiv {
float: right;
margin-right: 20px;
}

.react-datepicker-wrapper {
margin-bottom: 5px;
padding-left: 5px;
width: 50% !important;

}