.notification-page {
    margin-left: 50px;
}

.location-options, .user-options {
    width: 200px;
    height: 35px;
    border: 1px solid black;
}

.no-location-selected {
    display: none;
}

.notification-user {
    display: contents
}

.addtoList-btn {
    height: 35px;
    margin-left: 20px;
}

.userbylocation-list {
    padding: 10px;
    border: 0.5px solid #D1D1D1;
}

.userbylocation-list button {
        width: 100px;
}

.notification-name {
    margin-left: 10px;
}

.notification-user-list {
    display: flex;
    align-items: center;    
}

.notification-user-list-holder {
    width: 70%;
}

.notification-loading-icon {
text-align: center;
margin-top: 200px;
}