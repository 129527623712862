.fullBar { 
    height: 100%;
}

.sidebarStyle {
  width: 200px;
  height: 100%;
  background-color: #006730;
  color: black;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0px;
}

.version {
    position: absolute;
    bottom: 110px;
    left: 75px;
    color: white;
    font-size: 14px;
}

.appSidebar {
    position: Fixed;
    top: 100px;
    width: 100%;
    height: 100%;
}

.side-bar-outer {
    font-family: Graphik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height */
    color: #FFFFFF;
    flex: none;
    order: 3;
    flex-grow: 0;
    height: 40px;
    padding-top: 10px;
}

.side-bar-outer-selected {
    font-family: Graphik;
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    flex: none;
    order: 3;
    flex-grow: 0;
    height: 40px;
    padding-top: 10px;
    font-weight: bold;
}

.side-bar-outer:hover {
    font-style: normal;
    font-weight: bold;
    background-color: lightgreen;
}

@media only screen and (max-width: 768px) {
    .appSidebar {display: none;}
}
