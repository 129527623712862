.loading-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading-icon {
  position: absolute;
  top: 50%;
  left: 50%;
}
