.toolbar-container {
  display: flex;  
  margin-bottom: 10px;
}

.search-box, .export-btn {
  flex: 1;  
}  

.export-btn {
  text-align: right;  
}

.export-open {
  margin-top: 30px;
  height: 30px;
}

.search-input {
  max-width: 350px !important; 
  width: 350px !important; 
  border: 1px solid gray !important;
}

.market-detail-column,
.working-detail-column {
  float: left;
  width: 50%;
  padding: 10px;
}

.market-detail-row:after,
.working-detail-row:after {
  content: '';
  display: table;
  clear: both;
}

.data-item {
  margin-bottom: 10px;
}

.no-data {
  font-weight: bold;
  color: red;
  margin-bottom: 50px;
}

.order-detail-label {
  font-weight: 550;
}

.fill-order-inputs input {
  border: 1px solid black !important;
}

.fill-price-input,
.fill-qty-input,
.fill-bushels-input,
.bushel-count {
  display: inline-block;
}

.fill-qty-input,
.fill-bushels-input,
.bushel-count {
  margin-left: 40px;
}

.fill-price-input {
  margin-left: 20px;
}

.bushel-count {
  width: 180px;
  font-size: 14px;
}

#adminwindow {
  background-color: white !important;
}

.all-orders-form {
  position: relative;
  background-color: white;
  height: 100%;
  margin-left: auto;
  margin-right: auto;  
  justify-content: center;
  align-items: center;
}

.all-open-orders-grid, .all-hedge-orders-grid {
  margin: 0px 10px 0px 10px;
  padding-bottom: 10px;
}

.fill-order-container {
  margin: 10px;
}

.order-detail-container {
  margin: 0px 10px;
}

.hedgefills-mobile {
  display: none;  
}

@media only screen and (max-width: 768px) {
  
.export-open {  
  height: 30px;
}

.search-input {
  max-width: 300px !important; 
  width: 300px !important;
  border: 1px solid gray !important;
}

.toolbar-container {display: block;}

.export-btn {
  text-align: left;  
}

.export-open {
  margin-top: 5px;
  height: 30px;
}
/* 
.all-hedge-orders-grid {
 display: none;
}

.hedgefills-mobile {
  display: block;  
} */
}
.confirm-cancel {
  padding: 20px;
  max-width: 225px;  
  background-color: #ff6358;
  border: 1px solid rgba(0,0,0,.05);
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 3%), 0 4px 5px 0 rgb(0 0 0 / 4%);
  border-color: #ff6358;
  color: white;
  text-align: center;
}

.yes-button {
  width: 75px;
  padding: 5px;
  border-radius: 5px;
  border-color: white;
  background-color: white;
  color: black;
  border: 1px solid lightgray;
  cursor: pointer;
}

.no-button {
  width: 75px;
  padding: 5px;
  border-radius: 5px;
  border-color: white;
  background-color: white;
  color: black;
  border: 1px solid lightgray;
  margin-left: 15px;
  cursor: pointer;
}

.no-button:hover {
  background-color: lightgrey;
  color: white;
}

.yes-button:hover {
  background-color: lightgrey;
  color: white;
}

.cancel-success {
  padding: 20px;
  text-align: center;
  background-color: rgb(197, 237, 197);
  border-radius: 5px;
}

.ok-button {
  width: 100px;
}

.cancel-button {
  padding-top: 20px;
}

.detail-buttons {
  display: flex;
  justify-content: space-between;
}

.update-order-btn {
  margin-right: 20px;
  max-height: 40px;
  padding: 2px 15px;
  border-radius: 5px;
  border: 1px #ff6358 solid;
  background-color: #ff6358;
  color: white;
  cursor: pointer;
  font-size: 14px;
}