.accordiontop {
    position: relative;
    background-color: white;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    /*margin-top: 10px;*/
    padding-top: 10px;
    justify-content: center;
    align-items: center;
}

.accordion {
    background-color: white !important;    
}

.item 
{
    margin: 0px 10px 0px 10px;
    padding-bottom: 10px;
}

.title {
    border: .5px solid gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 5px 5px 5px 5px;
    border-radius: 5px;
    color: #00732E;
    cursor:pointer;
}

.content {
    color: black;
    background-color: white;
    padding: 5px 0px 5px 0px;

    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
}

.content.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}

#arrowdown {
    top: 2.5px;
    right: 2.5px;

    margin-left: 0px;
}

#accordTitle {
    margin: 0px;}