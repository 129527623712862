
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
    box-sizing: border-box;
}

.whitelogo {
    margin-left: 371.5px;
}

.tech-support {
    width: auto;
    color: white;
    margin-left:550px;
    text-decoration: none !important;
}

.login-header {
    height: 90px;
    width: 100%;
    background-color: #00732E
}
/*.login-page {
    background-image: url(Assets/WheatBackground.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}*/
.login-container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    position: relative;
    overflow: hidden;
    width: 550px;
    max-width: 100%;
    min-height: 650px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
}

.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
    left: 0;
    width: 100%;
    z-index: 2;
}

.login-form {
    background-color: #FFFFFF;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

.login-logo {
    height: 100px;
    margin-top: 100px;
}

.login-field, .password-field {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
}

.signed-in-box {
    float: left;
    text-decoration: none;
    color: #333;
    font-size: 14px;
}

.sign-in-label {
    padding-left: 5px;
}

.sf-logo {
    float: right;
    color: #333;
    font-size: 14px;
    text-decoration: none;
}

.login-button {
    border-radius: 20px;
    border: 1px solid #00732E;
    background-color: #00732E;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 100%;
    margin-top: 100px;
    transition: transform 80ms ease-in;
    cursor: pointer;
}

.logout-holder {
margin-top:20px;
}

.logout-button {
width: 150px;
height: 40px;
border-radius: 5px;
background-color: #F39801;
color: white;
font-weight: bold;
}