@keyframes k-loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.k-i-loading.k-example-loading {
  font-size: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(144, 152, 165);
}

.k-i-loading.k-example-loading::before,
.k-i-loading.k-example-loading::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  content: '';
  box-sizing: inherit;
  border-radius: 50%;
  border-width: 0.05em;
  border-style: solid;
  border-color: currentColor;
  border-top-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
}
.k-icon.k-i-loading.k-example-loading::before,
.k-icon.k-i-loading::after {
  content: '';
}

.k-i-loading.k-example-loading::before {
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  animation: k-loading-animation 0.7s linear infinite;
}

.k-i-loading.k-example-loading::after {
  margin-top: -0.25em;
  margin-left: -0.25em;
  width: 0.5em;
  height: 0.5em;
  animation: k-loading-animation reverse 1.4s linear infinite;
}

.example-wrapper {
  min-height: 280px;
  align-content: flex-start;
}
.example-wrapper p,
.example-col p {
  margin: 20px 0 10px;
}
.example-wrapper p:first-child,
.example-col p:first-child {
  margin-top: 0;
}
.example-col {
  display: inline-block;
  vertical-align: top;
  padding-right: 20px;
  padding-bottom: 20px;
}
.example-config {
  margin: 0 0 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.event-log {
  margin: 0;
  padding: 0;
  max-height: 100px;
  overflow-y: auto;
  list-style-type: none;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background-color: white;
}
.event-log li {
  margin: 0;
  padding: 0.3em;
  line-height: 1.2em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.event-log li:last-child {
  margin-bottom: -1px;
}

.k-form .k-form-field {
  margin-top: 0px !important;
}

/* .k-form {
  margin-bottom: 30px;
} */

.form-first-column {
  width: 42.5%;
  float: left;
  margin-right: 2.5%;
  margin-left: 5%;
  align-content: center;
  align-items: center;
}

.contract-type,
.locationSection,
.order-group {
  background-color: #e1efe7;
  padding: 10px 0px;
  text-align: center;
  border-radius: 5px;
}

.contract-type-field,
.locationSection-field,
.order-group-field {
  display: inline-block;
}

#field-container {
  margin-top: 1em;
}

.quantity-field {
  display: inline-block;
  width: 70%;
  margin-right: 10px;
}

.uom-field {
  display: inline-block;
  width: 20%;
}

.futures-month-field {
  display: inline-block;
  width: 45%;
  margin-right: 10%;
}

.year-field {
  display: inline-block;
  width: 45%;
}

.form-second-column {
  width: 42.5%;
  float: right;
  margin-left: 2.5%;
  margin-right: 5%;
  align-content: center;
  align-items: center;
}

.delivery-period-container {
  height: 128px;
}

.delivery-period-label {
  float: left;
  margin-top: 1em;
  width: 30%;
}

.delivery-date-range {
  float: right;
  width: 70%;
}

.new-order-detail {
  display: flex;
}

.first-column {
  flex: 1;
  width: 50%;
  margin-left: 20px;
}

.second-column {
  width: 50%;
  margin-left: 20px;
}

.action-buttons {
  margin-left: 20px;
}

@media only screen and (max-width: 768px) {
  .form-second-column {
    width: 100%;
    float: none;
    margin-left: 2.5%;
    margin-right: 0%;
    align-content: center;
    align-items: center;
  }
  .form-first-column {
    width: 100%;
    float: none;
    margin-right: 2.5%;
    margin-left: 0%;
    align-content: center;
    align-items: center;
    }
  }