.loading-details {    
        text-align: center;
        margin: 20px 0px;        
}
.user-window
{
    width: 100%;
}

.new-user {
    float: right;
    background-color: #5fb471;
    border-style: solid;
    border-color: gray;
    border-width: .5px;
    border-radius: 5px;
    margin-top: 10px;
    height: 50px;          
    font-family: Arial;
    font-style: normal;
    font-weight: bold;    
    line-height: 16px;
    min-width: 150px;
    margin-bottom: 10px;
}

    .new-user:hover {
        background-color: #406650;
    }

.add-user-btn{
    display: block;  
    margin: 0px auto 20px;
    padding: 5px;
    width: 150px;
    height: 50px;
    background-color: #F39801;
    border: rgb(255, 255, 255);
    border-radius: 5px;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;        
}

.add-button {
    margin-right: 10px;
    margin-top: 10px;
    float: right;
    background-color: #5fb471;    
}

.vendor-upload {    
    float: right;
    background-color: #5fb471;
    margin-top: 10px;
}

.user-loading-icon{
    text-align: center;
    margin-top: 50px;
}

.user-container{
    height: auto;
}

.activate-user-btn {
    background-color: #5fb471 !important;
}

.deactivate-user-btn {
    background-color: #ff4500 !important;
}

.search-input{
    height: 40px;
    margin-top: 10px;
}

.search-add-container .ant-space-item  {
    width: 100%;
}

.close-button{
    cursor: pointer;
}

.search-add-container {
    width: 100%
  }

  .user-container {
    display: flex;
    flex-direction: column;
    margin: 1.5rem;
  }
  
  .return-button {
    display: flex;
    justify-content: space-between;
  }
  
  .form-header {
    margin-bottom: 0;
  }